@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.header {
  height: rem(68px);
  opacity: 0;
  transform: translateY(-100%);

  &.isIn{
    animation: headerIN .8s $easeOutCubic forwards;
  }

  .container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;

    .logo {
      cursor: pointer;
      font-size: rem(15px);
      line-height: rem(20px);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: rem(8px);
      font-weight: 500;

      span {
        width: rem(168px);
        color: var(--black-color);

        svg {
          width: 100%;
          height: auto;

          path {
            fill: var(--black-color)
          }
        }
      }
    }

    .tabList {
      display: flex;
      gap: rem(40px);

      &_item {
        font-family: 'JetBrains Mono', monospace;
        color: var(--black-color);
        list-style: none;
        font-size: rem(14px);
        line-height: rem(20px);
        font-weight: 500;
        cursor: pointer;
        text-transform: uppercase;

        &.active {
          color: var(--active-color);
        }
      }

      @include mobile {
        display: none;
      }
    }

    .menuBtn {
      display: none;
      cursor: pointer;

      svg {
        path {
          fill: var(--black-color)
        }
      }

      @include mobile {
        display: block;
      }
    }

    @include mobile {
      flex-wrap: wrap;
    }
  }
}

.mobileMenu {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  transition: 0.15s;
  transform: translateX(100%);

  .container {
    .mobileMenuList {
      &_item {
        font-family: 'JetBrains Mono', monospace;
        color: var(--black-color);
        list-style: none;
        font-size: rem(20px);
        line-height: rem(28px);
        padding: rem(48px) 0;
        border-bottom: 1px solid #ECECEC;
        text-transform: uppercase;
      }
    }
  }

  &_btn {
    position: absolute;
    bottom: rem(48px);
    left: 50%;
    transform: translateX(-50%);
    padding: rem(28px);
    border: 1px solid #ECECEC;
    border-radius: 100px;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.08);

    svg {
      path {
        fill: var(--black-color)
      }
    }
  }

  &.active {
    transform: translateX(0);
  }
}

@keyframes headerIN {
  0%{
    transform: translateY(-100%);
    opacity: 0;
  }
  100%{
    transform: translateY(0%);
    opacity: 1;
  }
}
