@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.logo {
  display: flex;
  gap: 6px;
  align-items: center;

  svg {
    max-width: 100%;
    max-height: 100%;
  }
}
